$(document).ready(function(){
    console.log("hello")
    $('[data-slick]').slick({
        prevArrow: '<button type="button" class="icon icon__arrow slick-prev"></button>',
        nextArrow: '<button type="button" class="icon icon__arrow slick-next"></button>',
        dots: true,
        appendDots: $('[data-slick-dots]')
    });
  });
      

$("[data-trigger='mainmenu']").click(function(){
    var $this = $(this);
    var button = $('[data-trigger="mainmenu"]');
    if($this.hasClass("active") && button.hasClass("active")){
        $this.addClass("anim-end")
        setTimeout(function(){
            $this.removeClass("active")
            button.removeClass("active")
            $this.closest("body").removeClass("mainmenu-open")
        }, 250)
        setTimeout(function(){
            $this.removeClass("anim-end")
        }, 500)
    } else{
        $this.addClass("anim-start")
        setTimeout(function(){
            $this.addClass("active")
            button.addClass("active")
            $this.closest("body").addClass("mainmenu-open")
        }, 150)
        setTimeout(function(){
            $this.removeClass("anim-start")
        }, 500)
    }
})